<template>
  <div class="clockin-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.itemName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>拍照打卡</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-bar">
      <div class="left">
        <el-form :inline="true" :model="query" class="search-form-inline">
          <el-form-item>
            <el-input v-model="query.title" placeholder="请输入打卡地点名称" clearable @clear="getDataList"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getDataList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <el-button type="primary" @click="addClockin">添加打卡点</el-button>
      </div>
    </div>
    <div class="page-content" v-if="dataList.length > 0">
      <el-table :data="dataList" border style="width: 100%" max-height="500">
        <el-table-column prop="id" label="#" width="80"></el-table-column>
        <el-table-column prop="title" label="打卡点名称"></el-table-column>
        <el-table-column prop="status" label="打卡上传权限" width="200">
          <template slot-scope="scope">
            <el-tag type="warning" effect="dark" v-if="scope.row.upload_type === 1">仅限指定人员</el-tag>
            <el-tag type="success" effect="dark" v-if="scope.row.upload_type === 2">所有人均可上传</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="clockinNum" label="已打卡人数" width="100"></el-table-column>
        <el-table-column prop="praiseNum" label="已点赞人次" width="100"></el-table-column>
        <el-table-column fixed="right" label="操作" width="420">
          <template slot-scope="scope">
            <el-button size="small" type="warning" @click="setAddUser(scope.row.id)" v-if="scope.row.upload_type === 1">设置上传人员</el-button>
            <el-button size="small" @click="contentItem(scope.row)">查看打卡列表</el-button>
            <el-button size="small" type="success" @click="editItem(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="small" @click="delItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.currentPage" :page-sizes="[20, 50, 100]" :page-size="query.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="query.total"></el-pagination>
      </div>
    </div>
    <div class="no-list" v-else>
      <el-empty :image-size="200"></el-empty>
    </div>
    <!-- 添加上传人员弹窗 -->
    <el-dialog title="添加上传人员" :visible.sync="addUsersVisible" width="60%" :before-close="addUserClose">
      <el-alert
        title="特别提醒"
        type="success"
        description="被选择的用户可在小程序端所有分类下均可上传图片,被选取用户在小程序端至少需要进入一次本会议" style="text-align: left">
      </el-alert>
      <div class="dialog-form">
        <el-table ref="userTable" :data="userList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="m_id" label="混合ID" width="120"></el-table-column>
          <el-table-column prop="openid" label="Openid"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="nick" label="昵称"></el-table-column>
          <el-table-column label="头像" width="120">
            <template slot-scope="scope">
              <el-avatar :src="scope.row.avatar"></el-avatar>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addUsersVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUserSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
      nowTime: Number(String((new Date()).getTime()).slice(0, 10)),
      query: {
        title: '',
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      message: '复制链接',
      dataList: [],
      // 限制上传打卡人员相关
      addUsersVisible: false,
      userList: [],
      activeClockinId: 0
    }
  },
  created () {
    this.getDataList()
  },
  methods: {
    async getDataList () {
      const { data: res } = await this.$http.get('/shell-clockin-list/' + this.$route.params.mId, { params: this.query })
      if (res.status === 200) {
        this.dataList = res.data.list.data
        this.query.total = Number(res.data.list.total)
        this.query.currentPage = Number(res.data.list.current_page)
        this.query.pageSize = Number(res.data.pageSize)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 添加打卡点
    addClockin () {
      this.$router.push({ path: '/admin/apps/shell/content/clockin/' + this.$route.params.mId + '/add', query: { meetingName: this.$route.query.itemName } })
    },
    // 编辑打卡点
    editItem (id) {
      this.$router.push({ path: '/admin/apps/shell/content/clockin/' + this.$route.params.mId + '/edit/' + id, query: { meetingName: this.$route.query.itemName } })
    },
    // 删除
    async delItem (id) {
      const { data: res } = await this.$http.delete('/shell-clockin/' + id)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getDataList()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 查看打卡列表
    contentItem (row) {
      this.$router.push({ path: '/admin/apps/shell/content/clockin/' + this.$route.params.mId + '/clockinId/' + row.id + '/clockinName/' + row.title, query: { meetingName: this.$route.query.itemName } })
    },
    // 限制打卡上传人员
    async setAddUser (id) {
      this.activeClockinId = id
      const { data: res } = await this.$http.get('/shell-clockin-user-list', { params: { m_id: this.$route.params.mId, tid: this.activeClockinId } })
      if (res.status === 200) {
        this.userList = res.data.userList
        this.$nextTick(() => {
          this.userList.forEach(row => {
            res.data.userSelection.forEach(row2 => {
              if (row2.m_id === row.m_id) {
                this.$refs.userTable.toggleRowSelection(row)
              }
            })
          })
        })
        this.addUsersVisible = true
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭选择用户弹出窗
    addUserClose () {
      this.addUsersVisible = false
    },
    handleSelectionChange (val) {
      this.userSelection = val
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.userTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.userTable.clearSelection()
      }
    },
    // 提交用户
    async addUserSubmit () {
      const { data: res } = await this.$http.post('/shell-clockin-user', { userSelection: this.userSelection, tid: this.activeClockinId })
      if (res.status === 200) {
        this.addUsersVisible = false
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 更改每页显示数量
    handleSizeChange (val) {
      this.query.pageSize = val
      this.getDataList()
    },
    // 更改页码
    handleCurrentChange (val) {
      this.query.currentPage = val
      this.getDataList()
    }
  }
}
</script>

<style scoped>
.search-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-form-inline .el-form-item{
  margin-bottom: 0;
}
.add-form{
  padding: 0 40px;
  text-align: left;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
.el-form-item{
  margin-bottom: 8px;
}
.el-descriptions{
  padding: 0 40px;
}
.el-radio-group .el-radio{
  margin-bottom: 10px;
}
</style>
